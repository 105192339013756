import React from 'react';
import { useState } from 'react';
import { ScrollToTop } from '../../components/ScrollToTop';
import { Button, Form, Input, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Alert } from 'react-bootstrap';
import axios from 'axios';


const CareersPage = () => {
    const [open, setOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} is required!',
        types: {
            // eslint-disable-next-line
            email: '${label} is not a valid email!',
            // eslint-disable-next-line
            number: '${label} is not a valid number!',
        },
        number: {
            // eslint-disable-next-line
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const onFinish = async (values) => {
        const formData = new FormData();
        formData.append("name", values.user.name);
        formData.append("email", values.user.email);
        formData.append("website", values.user.website);
        formData.append("comments", values.user.comments);
        if (fileList.length > 0) {
            formData.append("resume", fileList[0]);
        }

        console.log(values);

        try {
            const response = await axios.post("https://menara-mailer-c9553dd9f56c.herokuapp.com/apply", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("File uploaded successfully. Server responded with:", response.data);
            setFormSubmitted(true);
            setSubmitStatus({ type: 'success', message: 'Application submitted successfully!' });
        } catch (error) {
            console.error("There was an error uploading the file: ", error);
            setSubmitStatus({ type: 'error', message: 'There was an error submitting your application.' });
        }
    };

    return (
        <main className='careers-page container bg-white'>
            <div className='container'>
                <ScrollToTop />
                <h1>Start doing work that matters</h1>
                <p>Looking for an exciting career in web development?
                    Join our team and help us create innovative solutions
                    for our clients! We are always on the lookout for
                    talented professionals who are passionate about web
                    development and eager to learn and grow. </p>
            </div>
            <calendar />
            <div className="container py-5">
                <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.3s">
                    <div className="tab-content">
                        <div className="job-item p-4 mb-4">
                            <div className="row g-4">
                                <div className="col-sm-12 col-md-8 d-flex align-items-center">
                                    <div className="text-start ps-4">
                                        <h5 className="mb-3">UX Designer</h5>
                                        <span className="text-truncate me-3"><i className="fa fa-map-marker-alt text-primary me-2"></i>Remote (US)</span>
                                        <span className="text-truncate me-3"><i className="far fa-clock text-primary me-2"></i>Full Time</span>
                                        <span className="text-truncate me-0"><i className="far fa-money-bill-alt text-primary me-2"></i>$60/hr</span>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                                    <div className="d-flex mb-3">
                                        <button className="btn btn-primary" type="primary" onClick={() => setOpen(true)}>Apply Now</button>
                                    </div>
                                    <small className="text-truncate"><i className="far fa-calendar-alt text-primary me-2"></i>Date Posted: 12 June, 2023</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="UX Designer"
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={1000}
            >
                <div className="row gy-5 gx-4">
                    {formSubmitted ? (
                        submitStatus && submitStatus.type === 'success' &&
                        <Alert variant='success'>Thanks for submitting your application. We'll be in touch soon!</Alert>
                    ) : (
                        <><div className="col-lg-8">
                            <div className="mb-5">
                                <h4 className="mb-3">Role Overview</h4>
                                <p>We are seeking a talented and passionate UX Designer to join our web development team. The ideal candidate will have a keen eye for detail, a deep understanding of user-centered design principles, and the ability to translate user needs and business goals into innovative and compelling design solutions.</p>
                                <h4 className="mb-3">Responsibilities</h4>
                                <ul className="list-unstyled">
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Collaborate closely with product managers, developers, and other stakeholders to understand user needs, business requirements, and technical constraints.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Create user personas, user journeys, wireframes, prototypes, and user interface specifications.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Conduct usability testing and user research, and interpret analytics data to drive design decisions and enhance user experience.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Champion a design-thinking culture that values user feedback and iterative design practices.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Develop and maintain design systems, style guides, and UX/UI best practices to ensure consistency across all platforms.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Present design concepts and deliverables to stakeholders, addressing feedback and making revisions as required.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Stay up-to-date with the latest UX/UI trends, techniques, and technologies.</li>
                                </ul>
                                <h4 className="mb-3">Qualifications</h4>
                                <ul className="list-unstyled">
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Bachelor's degree in Design, Human-Computer Interaction, or a related field, or equivalent practical experience.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Proven experience as a UX Designer, UI Designer, or similar role.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Strong portfolio showcasing your UX skills, creativity, problem-solving abilities, and design process.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Proficiency in design and prototyping tools such as Sketch, Figma, Adobe XD, InVision, etc.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Knowledge of HTML/CSS; JavaScript is a plus.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Excellent visual design skills with sensitivity to user-system interaction.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Ability to work in a fast-paced environment and manage multiple projects simultaneously.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Excellent communication and presentation skills.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Experience in user research methods, usability testing, and interpreting analytics data.</li>
                                </ul>
                                <h4 className="mb-3">What We Offer</h4>
                                <ul className="list-unstyled">
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Competitive salary and benefits package</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>A collaborative, innovative, and supportive work environment.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>Opportunities for professional growth and advancement.</li>
                                    <li><i className="fa fa-angle-right text-primary me-2"></i>The chance to create a significant impact on the digital strategy of a growing organization.</li>
                                </ul>
                                <p>Menara Solutions is an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, or protected veteran status and will not be discriminated against on the basis of disability.</p>
                            </div>

                            <div className="">
                                <h4 className="mb-4">Apply For The Job</h4>
                                <Form
                                    {...layout}
                                    name="nest-messages"
                                    onFinish={onFinish}
                                    style={{
                                        maxWidth: 600,
                                    }}
                                    validateMessages={validateMessages}
                                    className='row g-3'
                                >
                                    <Form.Item
                                        name={['user', 'name']}
                                        label="Name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        className='col-12 col-sm-6'
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={['user', 'email']}
                                        label="Email"
                                        rules={[
                                            {
                                                type: 'email',
                                                required: true,
                                            },
                                        ]}
                                        className='col-12 col-sm-6'
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={['user', 'resume']}
                                        label="Resume"
                                        className='col-12 col-sm-6'
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Upload
                                            name="resume"
                                            fileList={fileList}
                                            beforeUpload={file => {
                                                setFileList([file]);
                                                return false; // prevent automatic upload
                                            }}
                                            onRemove={() => setFileList([])}
                                            listType="picture"
                                        >
                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        name={['user', 'website']}
                                        label="Website"
                                        rules={[
                                            {
                                                type: 'website',
                                            },
                                        ]}
                                        className='col-12 col-sm-6'
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={['user', 'comments']}
                                        label="Comments"
                                    >
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item
                                        className='p-3'
                                    >
                                        <button className="btn btn-primary" type="primary" htmlType="submit">
                                            Submit
                                        </button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </div><div className="col-lg-4">
                                <div className="bg-light rounded p-5 mb-4 wow slideInUp" data-wow-delay="0.1s">
                                    <h4 className="mb-4">Job Summary</h4>
                                    <p><i className="fa fa-angle-right text-primary me-2"></i>Published On: 12 June, 2023</p>
                                    <p><i className="fa fa-angle-right text-primary me-2"></i>Job Nature: Full Time</p>
                                    <p><i className="fa fa-angle-right text-primary me-2"></i>Salary: $60/hr</p>
                                    <p><i className="fa fa-angle-right text-primary me-2"></i>Location: Remote (US)</p>
                                </div>
                            </div></>
                    )}
                </div>
            </Modal>
        </main>
    );
};

export default CareersPage;
