import React from 'react';
// import { Link } from 'react-router-dom';
import ServiceSmallCard from '../../../components/cards/ServiceSmallCard';

const OurSkillsSection = () => {
  const list = [
    {
      imageUrl: '/assets/images/MVP Development.png',
      titleOne: 'E-Commerce',
      titleTwo: 'Development',
      text: `Unlock the full potential of your online business with our tailored E-Commerce solutions. Say goodbye to the hassle of assembling an in-house development team and let us handle the complexities of building your digital storefront. From startup to scaling, our expert developers will swiftly bring your vision to life, ensuring your MVP is launched in record time. With our comprehensive approach to E-Commerce development, you can focus on what matters most—growing your business and reaching new heights in the online marketplace.`,
      icons: [
        {
          icon: 'fa-bigcommerce',
          name: 'BigCommerce',
        },
        {
          icon: 'fa-shopify',
          name: 'shopify',
        },
      ]
    },
    {
      imageUrl: '/assets/images/Web Development.png',
      titleOne: 'Web',
      titleTwo: 'Development',
      text: `Attract and retain customers with a high-performance web application tailored to your business needs. Our skilled team of developers specializes in a wide range of technologies, including Node.js, Ruby on Rails, Vue.js, React, AngularJS, and quality assurance. Whether you require a robust backend, a seamless frontend, or a full-stack solution, we'll build the perfect application to propel your business forward in the digital landscape.`,
    },
    {
      imageUrl: '/assets/images/Mobile Development.png',
      titleOne: 'Mobile',
      titleTwo: 'Development',
      text: `Take your mobile app to new heights with our hybrid development approach, enabling you to reach both Android and iOS users simultaneously. Leveraging advanced technologies like React Native or Ionic, our talented developers will seamlessly integrate backend systems and APIs, ensuring a smooth and efficient launch. With our mobile development expertise, you can conquer the mobile market and captivate a wider audience.`,
    },
    {
      imageUrl: '/assets/images/Product Design.png',
      titleOne: 'product',
      titleTwo: 'design',
      text: `Elevate your app's appeal and usability with our expert product design services. Our meticulous designers will create stunning prototypes, wireframes, and user interfaces that showcase your app's unique personality and deliver an intuitive user experience. With a focus on responsive design and impactful data visualization, our observant designers will ensure every aspect of your app looks amazing, leaving a lasting impression on your users.`,
    },
  ];

  return (
    <section>
      <div className='container position-relative'>
        {/* <span className='position-absolute end-0 top-40'>
          <img
            className='d-block h-100 w-100'
            src='/assets/images/bg-plus.png'
            alt='...'
          />
        </span> */}
        <div className='row gy-4'>

          <div className=''>
            <div>
              <ul className='nav row row-cols-1 row-cols-sm-2 gy-4'>
                {list.map((item) => (
                  <li>
                    <ServiceSmallCard item={item} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSkillsSection;
